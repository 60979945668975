<template>
  <div>
    <h2>Manager Dashboard Calendar - {{ currentYear }}</h2>
    <v-layout wrap justify-center>
      <v-flex lg4 v-for="(month, index) in months" :key="index" pa-5>
        <v-card @click="openDialog(index)">
          <v-layout wrap>
            <v-flex lg12>
              <v-card-title>{{ month }}</v-card-title>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- Dialog -->
    <v-dialog v-model="dialog" persistent @input="handleDialogToggle">
      <v-card>
        <v-card-title>{{ months[dialogIndex] }} - {{ currentYear }}</v-card-title>
        <v-card-text>
          <div class="days">
            <div
              class="day"
              v-for="day in days"
              :key="day"
              :class="{ blocked: isDateBlocked(dialogIndex, day) }"
            >
              {{ day }}
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      dialog: false,
      dialogIndex: null,
      disableddates: [],
      currentYear: new Date().getFullYear(),
      days: [],
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    dialog(val) {
      // Clear dialog index when dialog is closed
      if (!val) {
        this.dialogIndex = null;
        this.days = [];
      }
    },
  },
  methods: {
    updateDays(monthIndex) {
      const daysInMonth = new Date(this.currentYear, monthIndex + 1, 0).getDate();
      this.days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    },
    isDateBlocked(monthIndex, day) {
      // Format the date to 'DD/MM/YYYY'
      const formattedDate = `${String(day).padStart(2, "0")}/${String(
        monthIndex + 1
      ).padStart(2, "0")}/${this.currentYear}`;
      return this.disableddates.includes(formattedDate); // Check if the date is blocked
    },
    openDialog(index) {
      this.dialogIndex = index;
      this.updateDays(index);
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    handleDialogToggle(val) {
      if (!val) {
        this.dialogIndex = null; // Reset index when dialog is closed
      }
    },
    getData() {
      axios({
        method: "GET",
        url: "/division/ibManager/booking/dates",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            // Process the new format of dates
            this.disableddates = response.data.dates.map((date) => {
              const [day, month, year] = date.split("/"); // Split date into parts
              return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`; // Ensure consistent formatting
            });
          } else {
            console.error(response.data.msg);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style scoped>
.days {
  display: flex;
  flex-wrap: wrap;
}
.day {
  width: calc(100% / 7); /* Adjust as needed */
  text-align: center;
  padding: 5px;
  cursor: pointer;
}
.blocked {
  color: red;
  font-weight: bold;
}
</style>
